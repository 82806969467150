import { Component } from '@angular/core';

@Component({
  selector: 'app-workplace-forum',
  templateUrl: './workplace-forum.component.html',
  styleUrls: ['../../app.component.css', './workplace-forum.component.css'],
})

export class WorkplaceForumComponent {

}
