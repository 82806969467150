import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../services/notifications/notifications.service';
import { SharedEmitterService } from 'app/shared/shared.emitter.service';
import { GlobalService } from 'app/services/global.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  providers: [ NotificationService ]
})

export class NavbarComponent {

  public notification: any;
  public total: number;
  public loggedUser: any;
  public token;

  constructor(
    private router: Router,
    private _NotificationService: NotificationService,
    public global: GlobalService,
    public http: HttpClient
  ) { }

  ngOnInit() {
    this.token = sessionStorage.getItem("tokenLogin");
    console.log('tokenLogin NAVBAR',sessionStorage.getItem("tokenLogin"));
    this.getLoggedUser();
    this.getNotification();
    SharedEmitterService.updatePhoto.subscribe(
      data => {
        if(data) {
          this.getLoggedUser();
        }
      }
    );
    setInterval(() => {
      this.getNotification();
    }, 300000)
  }

  getNotification() {
    this._NotificationService.getResources().subscribe( res=> {
      this.notification = res.data;
      this.total = res.total;

      console.log('_NotificationService')
      console.log(res)
    })
  }

  goTo(item) {
    this._NotificationService.createResource({id: item.id}, {router: item.id}).subscribe( res => {
      if(item.type === 0) {
        this.router.navigate(['/training', item.id]);
      } else if(item.type === 2) {
        this.router.navigate(['/visualizador/mini-etapa', item.id]);
      } else if(item.type === 3) {
        this.router.navigate(['/visualizador/etapa-develop', item.id]);
      }
    })
  }

  getLoggedUser(): any {
    const session = JSON.parse(sessionStorage.getItem('loggedUser'));
    this.loggedUser = session.data;
  }

  onLogout() {
    sessionStorage.removeItem('loggedUser');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('tokenLogin');
    this.router.navigate(['/active-directory']);
    this.http.get('http://intranet.bioritmo.com.br/sign_out?app=asap').subscribe(response => {
      console.log('Logout', response);
    });
  }

}
