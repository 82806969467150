import { Component } from '@angular/core';

@Component({
  selector: 'app-workplace-ranking',
  templateUrl: './workplace-ranking.component.html',
  styleUrls: ['../../app.component.css', './workplace-ranking.component.css'],
})

export class WorkplaceRankingComponent {

}
