import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-contributors',
  templateUrl: './contributors.component.html'
})

export class ContributorsComponent {

  @Input() contributors;

}
