import { Component } from '@angular/core';
import { AreaPermissionService } from '../../services/documentos/area-permission.service';

@Component({
  selector: 'app-training-and-products',
  templateUrl: './training-and-products.component.html',
  providers: [ AreaPermissionService ]
})

export class TrainingAndProductsComponent {

  areas: any;

  constructor( private _AreaPermissionService: AreaPermissionService ) { }

  ngOnInit() {
    this.getAreas();
  }

  getAreas() {
    this._AreaPermissionService.getResources().subscribe( res=> {
      console.log(res)
      this.areas = res.data;
    })
  }

}
