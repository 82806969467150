import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class Global {

    constructor(
        public dialog: MatDialog,
        public notification: NotificationsService
    ) {
        
    }

    public loggedUser() {
        let user:any = sessionStorage.getItem("loggedUser");
        if (user) {
            user = JSON.parse(user).data;
            return user;
        }
        return null;
    }

}
