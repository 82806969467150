import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { BaseService } from '../loja/base.service';

@Injectable()
export class NotificationService extends BaseService {

  constructor(protected http: Http) {
    super(http, 'api/v2/client', 'home/notifica')
  }

}