import { Component, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-menu-forum',
  templateUrl: './menu-forum.component.html'
})

export class MenuForumComponent {

  @Input() forum;

  constructor( ) {
    moment.locale('pt-br');
  }

}
