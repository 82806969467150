import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TrailService } from '../../../@core/services/trail.service';
import { Trail } from '../../../@core/models/trail';

@Component({
    selector: 'dialog-challenges-details-modal',
    templateUrl: 'challenges-details-modal.component.html',
    styleUrls: ['./challenges-details-modal.component.scss'],
    providers: [ Trail, TrailService]
  })
  export class ChallengesDetailsDialog {
  
    day;
    month;
    months = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio',
        'Junho', 'Julho', 'Agosto', 'Setembro',
        'Outubro', 'Novembro', 'Dezembro'
    ];
    loading = true;
    
    constructor(
      public dialogRef: MatDialogRef<ChallengesDetailsDialog>,
      private _trailService: TrailService,
      @Inject(MAT_DIALOG_DATA) public data
    ) {}
  
    onNoClick(): void {
      this.dialogRef.close();
    }
    
    ngOnInit() {
        this._trailService.aceptChallengeModules(this.data).subscribe( res=> {
            let date = res.mensagem.split('-');
            this.day = date[2];
            this.month = this.getMonth(date[1]);
            this.loading = false;
        })
    }

    getMonth(monthnum) {
        return this.months[monthnum - 1] || '';
    }
  
  }