import { Component } from '@angular/core';

@Component({
  selector: 'app-workplace-blox-modal',
  templateUrl: './workplace-blox-modal.component.html',
  styleUrls: ['../../app.component.css'],
})

export class WorkplaceBloxModalComponent {

}
