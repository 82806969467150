import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { BaseService } from '../loja/base.service';
import "rxjs/add/operator/map";

@Injectable()
export class InfluenciadoresService extends BaseService {

  constructor(protected http: Http) {
    super(http, 'api/v2/client', 'edital/influenciadores')
  }

  public notice(id) {
    return this.http.get(this.host + "/api/v2/client/edital/influenciadores/" + id, this.options).map((res: Response) => res.json())
  }

}
