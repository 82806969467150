import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TrailService } from '../../@core/services/trail.service';
import { Trail } from '../../@core/models/trail';
import { ChallengesDetailsDialog } from './challenges-details-modal/challenges-details-modal.component';
import { ChallengesDetailsCongrulationsDialog } from './challenges-details-congrulations/challenges-details-congrulations.component';

declare const $: any;

@Component({
  selector: 'app-challenges',
  templateUrl: './challenges.component.html',
  providers: [ Trail, TrailService ]
})

export class ChallengesComponent implements OnInit {

  @Input() idTrail;

  public day;
  public month;
  public months = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio',
      'Junho', 'Julho', 'Agosto', 'Setembro',
      'Outubro', 'Novembro', 'Dezembro'
  ];
  public acept = false;
  public aceptData;
  public loadingChart = true;
  public loading = true;
  public conclusion;

  constructor(
    private _trailService: TrailService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getChallenge(this.idTrail);
  }

  openModal() {
    const dialogRef = this.dialog.open(ChallengesDetailsDialog, {
      panelClass: 'dialog-container',
      width: '250px',
      data: this.idTrail,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getChallenge(this.idTrail);
    });
  }

  openCongrulations() {
    const cart = $('.icon-cart');
    cart.removeClass('rubberBand animated')

    const dialogRef = this.dialog.open(ChallengesDetailsCongrulationsDialog, {
      panelClass: 'dialog-container',
      width: '250px',
      data: this.idTrail,
    });

    dialogRef.afterClosed().subscribe(result => {
      setTimeout(() => {
        cart.addClass('rubberBand animated');
      }, 800);
    });
  }

  getChallenge(id): void {
    this._trailService.getChallengeModules(id).subscribe( res => {
      if (res.sucess) {
        const date = res.mensagem.split('-');
        this.acept = true;
        this.aceptData = res;
        this.day = date[2];
        this.month = this.getMonth(date[1]);
        this.loading = false;
        this.loadingChart = true;
        this.conclusion = res.conluido;
        if (res.dasafio && !res.modal) {
          this.openCongrulations();
        }
      }
    })
  }

  getMonth(monthnum) {
    return this.months[monthnum - 1] || '';
  }

}
