import {Injectable, OnInit} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/catch';
import {Observable} from 'rxjs/Observable';
import { GlobalService } from './global.service';

declare var swal: any;

@Injectable()
export class HomeRouteService implements CanActivate, OnInit {

  constructor(public global: GlobalService) {
  }

  ngOnInit(): void {
    console.log('iniciei');
  }

  canActivate(): Observable<boolean>|boolean {
        this.global.navLogo = 'assets/img/logo.png';
        this.global.homeRoute = '/universidade';
        return true;
  }
}
