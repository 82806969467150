import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/first';

import { User } from '../models/user';
import { BaseService } from './base.service';
import { LibraryHelper } from '../@core/helpers/library.helper';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthService extends BaseService {

    login(user: User, loginAD = false): any {
        let body;
        let endPoint;
        if (loginAD) {
            endPoint = "active-directory";
            body = {
                username: user.username,
                password: user.password
            };
        }
        else {
            endPoint = "oauth/access_token";
            body = {
                email: user.username,
                password: user.password
            };
        }

        let client_id = sessionStorage.getItem('client')
        return this.http.post(this.baseUrl + '/api/' + endPoint + '?client_id=' + client_id, body);
    }

    loginToken(token, brand = null) {
        let client_id = sessionStorage.getItem('client');

        let body: any = {
            token: token
        };

        if (brand == "bio") {
            body.brand = brand;
        }

        return this.http.post(this.baseUrl + '/api/oauth/access_token?client_id=' + client_id, body);
    }

    register(user: User, clientId: number): Observable<any> {
        const body = {
            client_id: clientId,
            user
        };
        const url = this.baseUrl + '/api/v2/client/register';
        return this.http.post(url, body);
    }

    isAuthenticated(): boolean {
        const token: string = sessionStorage.getItem('token');
        if (!token) {
            return false;
        }
        return true;
    }

    public getLinkStream() {
        // https://apiv3.plataformaasap.com.br/api/v2/admin/user/stream?client_id=20
        let client_id = sessionStorage.getItem('client')
        return this.http.get(this.baseUrl + '/api/v2/admin/user/stream?client_id=' + client_id, LibraryHelper.getHeaders());
    }

    setSessionToken(data): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const token = 'Bearer' + ' ' + data.token;
            sessionStorage.setItem('token', token);
            if (data.tokenLogin) {
                sessionStorage.setItem('tokenLogin', data.tokenLogin);
            }
            this.getUserLogged().subscribe(res => {
                this.verifica().subscribe(verifica => {
                    sessionStorage.setItem('smart_verifica', (verifica.success) ? '1' : '0');
                    if (res.success === false) {
                        resolve(false);
                    } else {
                        this.getLinkStream().subscribe(
                            (response: any) => {
                                res.data.link = response[0].link_stream;
                                sessionStorage.setItem('loggedUser', JSON.stringify(res));
                                sessionStorage.setItem("apiURL", "https://apiv3.plataformaasap.com.br/");
                                resolve(true);
                                console.log('LOGGED USER', res);
                            },
                            (error: any) => {
                                console.log('error', error);
                            }
                        )
                    }
                });
            }, err => reject(err));
        });
    }

    setSessionTokenLogin(data): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const token = 'Bearer' + ' ' + data.access_token;
            sessionStorage.setItem('token', token);
            this.getUserLogged().subscribe(res => {
                this.verifica().subscribe(verifica => {
                    sessionStorage.setItem('smart_verifica', (verifica.success) ? '1' : '0');
                    if (res.success === false) {
                        resolve(false);
                    } else {
                        sessionStorage.setItem('loggedUser', JSON.stringify(res));
                        resolve(true);
                    }
                });
            }, err => reject(err));
        });
    }

    tokenLogin(token, brand = null) {
        return new Promise((resolve,error) => {
            //this.blockUI.start("Token encontrado, efetuando Logon...")
            this.loginToken(token, brand).subscribe(
                (data) => {
                    console.log("tokenLogin.data:",data);
                    this.setSessionToken(data)
                        .then((response) => {
                            sessionStorage.setItem("tokenLogin", token);
                            resolve(response);
                        })
                        .catch((err) => {
                            error(err);
                        });
                },
                (err) => {
                    console.log("tokenLogin.error:",err);
                    error(err);

                }
            );
        });
    }

    socialAuth(body) {
        return this.http.post(environment.baseUrl + '/api/social_auth', body);
    }

    getUserLogged(): Observable<any> {
        return this.http.get(this.baseUrl + '/api/v2/client/user/authenticated', LibraryHelper.getHeaders());
    }

    verifica(): Observable<any> {
        return this.http.get(environment.baseUrl + '/api/v2/client/verifica', LibraryHelper.getHeaders());
    }

    complement(): Observable<any> {
        return this.http.get(environment.baseUrl + '/api/v2/admin/user/form', LibraryHelper.getHeaders());
    }

    complementSave(id: number, data: any): Observable<any> {
        return this.http.put(environment.baseUrl + '/api/v2/perfil/sobre/' + id, data, LibraryHelper.getHeaders());
    }

    verifyClient(clientId: number): Observable<any> {
        return this.http.post(environment.baseUrl + '/api/v2/client/verifyclient', { client_id: clientId }, LibraryHelper.getHeaders());
    }

    dismissStorage(): void {
        sessionStorage.clear();
    }

    syncActiveClient(client: string): void {
        sessionStorage.setItem('client', client);
    }

    getActiveClient(): any {
        return +sessionStorage.getItem('client');
    }

    userInfo(id): Observable<any> {
        return this.http.get(environment.baseUrl + '/api/v2/client/feed/userInfo/' + id, LibraryHelper.getHeaders());
    }

}
