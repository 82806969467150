import { Component } from '@angular/core';
import { HomeService } from 'app/services/home-smart/home.service';
import { ColaboradoresService } from 'app/services/colaboradores/colaboradores.service';
import { MainService } from 'app/services/main.service';
import { GlobalService } from 'app/services/global.service';

@Component({
  selector: 'app-workplace',
  templateUrl: './workplace.component.html',
  // styleUrls: ['../app.component.css', './workplace.component.css'],
  providers: [ HomeService, ColaboradoresService ]
})

export class WorkplaceComponent {

  homeInfo: any;
  trail: any;
  conquer: any;
  points: any;
  loading: boolean;
  contributors: any;

  constructor(
    public global: GlobalService,
    private _homeService: HomeService,
    private _ColaboradoresService: ColaboradoresService,
    public main: MainService
  ) {
    this.loading = true;
    this.getHomeInfos();
    this.getContributors();
  }

  getHomeInfos() {
    this._homeService.getResources()
      .subscribe( res => {
        console.log('getHomeInfos', res);
        this.homeInfo = res;
        if (res.data.fechado.length > 0) {
          this.trail = res.data.fechado[0];
        }
        const conquerDetail = {
          title: res.data.conquistaTitulo,
          file: res.data.conquistaArquivo,
          level: res.data.conquistaNivel
        }
        this.conquer = { conquista: conquerDetail, ranking: res.data.ranking };
        this.points = res.data.pontos;
        this.loading = false;
      }, err => {
        this.loading = false;
      })
  }

  getContributors() {
    this._ColaboradoresService.getResources()
    .subscribe( res => {
      this.contributors = res.data;
    })
  }

}
