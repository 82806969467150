import { Component, OnInit } from '@angular/core';
import { TutorService } from 'app/services/tutor/tutor.service';
import { AreaPermissionService } from '../../services/documentos/area-permission.service';
import { Global } from 'asap-crud';

declare const $: any;

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  providers: [ AreaPermissionService ]
})

export class SideMenuComponent implements OnInit {

    public isTutor = false;
    public areas;

    constructor(
      public global: Global,
        public tutorService:TutorService,
        private _areaPermissionService: AreaPermissionService
    ) {
        this.tutorService.profile().subscribe((response) => {
            this.isTutor = response.tutor;
        })
        this._areaPermissionService.getResources().subscribe( res=> {
          this.areas = res.data;
        })
    }

    ngOnInit() {

    $(document).ready(function () {

      $('#sidemenuCollapse').on('click', function () {
        $('.sidemenu, .content, .overlay').toggleClass('active');
      });

      $('.overlay').on('click', function () {
        $('.sidemenu, .content, .overlay').removeClass('active');
      });

      $('.components').on('click', function () {
        $('.sidemenu, .content, .overlay').removeClass('active');
      });

      $('.navbar-brand').on('click', function () {
        $('.sidemenu, .content, .overlay').removeClass('active');
      });

      $('.triangle').on('click', function () {
        $('.sidemenu, .content, .overlay').removeClass('active');
      });

    });

  }

}
