import { Component } from '@angular/core';
import { ChannelService } from 'app/services/channel.service';
import { Global } from 'app/@core/lib/global';

@Component({
    selector: 'app-file-library',
    templateUrl: './file-library.component.html',
    providers: [ ChannelService, Global ]
})

export class FileLibraryComponent {

    public library;
    public search = "";
    public page = null;
    public loading = false;

    constructor(
        public channelService: ChannelService
    ) {
        this.doSearch("");
    }

    public doSearch(search,nextPage=false) {
        if (!nextPage) this.library = null;
        this.search = search;
        this.channelService.fileGroupLibrary(this.search,this.page).subscribe((response:any) => {
            if (!nextPage) this.library = response.data;
            if (nextPage) this.library = this.library.concat(response.data);
            this.page = null;
            if (response.current_page != response.last_page) {
                this.page = response.current_page;
            }
        });
    }

    public moreVideos() {
        this.page++;
        this.doSearch(this.search,true);
    }

    listCondition() {
        return {query:'type=txt'}
    }

}
