import { Injectable } from '@angular/core';
import { TrailHomeService } from './home-smart/trails-home.service';
import { BaseService } from './loja/base.service';
import { Http } from '@angular/http';


@Injectable()
export class GlobalService extends BaseService {

    private trailHomeService: TrailHomeService;
    public themeLoaded = false;
    public theme;
    public training;
    public folders;
    public influencers;
    public development;
    public articles;
    public loading;
    public navLogo;
    public homeRoute;

    public params:any = {};


    constructor(
        public http: Http,
    ) {
        super(http,"","");
        this.trailHomeService = new TrailHomeService(http);   
        this.loadHomeData();   
    }

    public loadHomeData() {
        if (this.loggedUser()) {
            this.trailHomeService.getResources().subscribe((response) => {
                this.training = response.data.trilhas;
                this.folders = response.data.pastas;
                this.influencers = response.data.influenciadores;
                this.development = response.data.desenvolvimento;
                this.articles = response.data.artigos;
                this.loading = false;
            }, err => {
                this.loading = false;
            });            
        }

    }

    public loggedUser() {
        let user:any = sessionStorage.getItem('loggedUser');
        if (user) {
            user = JSON.parse(user);
            return user.data;
        }
        return null;
    }

    public clientID() {
        let id = sessionStorage.getItem('client');
        return id;
    }

    public convertToDataURLviaCanvas(url, outputFormat){
        return new Promise( (resolve, reject) => {
            let img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function(){
                console.log("img",img);
                let canvas = <HTMLCanvasElement> document.createElement('CANVAS'),
                ctx = canvas.getContext('2d'),
                dataURL;
                canvas.height = img.height;
                canvas.width = img.width;
                ctx.drawImage(img, 0, 0);
                dataURL = canvas.toDataURL(outputFormat);
                //callback(dataURL);
                canvas = null;
                resolve(dataURL); 
            };
            img.src = "https://cors-anywhere.herokuapp.com/" + url;
        });
    }

}
