import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'imagePipe'})
export class ImagePipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {}

    transform(value: any, command: any, width: any): any {
        if (command) {
            if (command == 'resize') {
                if (width) {
                    value = encodeURIComponent(value);
                    return 'http://apiv3.plataformaasap.com.br/api/image/resize/'+width+'?image=' + value; 
                }
            }
        }
        return value;
    }
}
