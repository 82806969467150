import { Component, OnInit } from '@angular/core';
declare const $: any;

@Component({
  selector: 'app-sidemenu-video',
  templateUrl: './sidemenu-video.component.html',
})

export class SideMenuVideoComponent implements OnInit {

    ngOnInit() {

      $(document).ready(function () {

        $('#sidemenuCollapse').on('click', function () {
          $('.sidemenu, .content, .overlay').toggleClass('active');
        });

        $('.overlay').on('click', function () {
          $('.sidemenu, .content, .overlay').removeClass('active');
        });

        $('.components').on('click', function () {
          $('.sidemenu, .content, .overlay').removeClass('active');
        });

        $('.navbar-brand').on('click', function () {
          $('.sidemenu, .content, .overlay').removeClass('active');
        });

        $('.triangle').on('click', function () {
          $('.sidemenu, .content, .overlay').removeClass('active');
        });

      });

  }

}
