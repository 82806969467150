import { Component, OnInit, ViewChild } from '@angular/core';
import { OwlCarousel } from 'ngx-owl-carousel';
import { InfluenciadoresService } from 'app/services/influenciadores/influenciadores.service';

@Component({
  selector: 'app-workplace-edital',
  templateUrl: './workplace-edital.component.html',
  styleUrls: ['./workplace-edital.component.css'],
  providers: [InfluenciadoresService]
})
export class WorkplaceEditalComponent implements OnInit {
  
  @ViewChild('owlElement', {static:false}) owlElement: OwlCarousel
  public notices;
  public loading;

  options = {
    items: 1,
    loop: true,
    center: true,
    margin: 0,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    URLhashListener: true,
    autoplay:true,
    autoplayTimeout:3000,
    autoplayHoverPause:true,
    nav: false,
    dots: false
  };

  constructor(
    public influenciadoresService: InfluenciadoresService
  ) { }

  ngOnInit() {
    this.getNotices();
  }

  getNotices() {
    this.influenciadoresService.getResources().subscribe((response:any) => {
      console.log("getNotices ->",response);  
      this.notices = response;
    });
  }

}
