import { Component, OnInit } from '@angular/core';

declare const $: any;

@Component({
  selector: 'app-sidemenu-tutor',
  templateUrl: './sidemenu-tutor.component.html',
  styleUrls: ['../../app.component.css', './sidemenu-tutor.component.css']
})

export class SideMenuTutorComponent implements OnInit {

  ngOnInit() {

    $(document).ready(function () {

      $('#sidemenuCollapse').on('click', function () {
        $('.sidemenu, .content, .overlay').toggleClass('active');
      });

      $('.overlay').on('click', function () {
        $('.sidemenu, .content, .overlay').removeClass('active');
      });

      $('.components').on('click', function () {
        $('.sidemenu, .content, .overlay').removeClass('active');
      });

      $('.navbar-brand').on('click', function () {
        $('.sidemenu, .content, .overlay').removeClass('active');
      });

    });

  }

}
