import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Component } from "@angular/core";
import { environment } from 'environments/environment';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, ActivatedRoute } from '@angular/router'
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { GlobalService } from "./services/global.service";
import { AuthService } from "./services/auth.service";


@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule],
    bootstrap: [AppComponent]
})


@Component({
    selector: 'app-root',
    template: `
        <block-ui message="Carregando dados" [delayStart]="500" [delayStop]="500">
        <router-outlet (activate)="scrollTop($event)"></router-outlet>
        <simple-notifications></simple-notifications>
        </block-ui>
    `,
    providers: [ AuthService ]
})
export class AppComponent {

    @BlockUI() blockUI: NgBlockUI;

    constructor(
        private router: Router,
        private global: GlobalService,
        private authService: AuthService
    ) {

        this.router.events.subscribe((event: RouterEvent) => {
            this.navigationInterceptor(event)
        })

        if (environment.production) {
            window.console.log = function () { };
        }
        sessionStorage.setItem("streamURL","https://smartfit-br.twcreativs.stream/api/");

    }

    public getQueryParams(route) {
        let uri = route.split("?");
        if (uri.length == 1) {
            return null;
        }
        else {
            let result:any = {}
            uri = uri[1];
            uri = uri.split("&");
            for (let params of uri) {
                let paramIdx = params.indexOf("=");
                let key = params.substring(0,paramIdx);
                let value = params.substring(paramIdx+1);
                result[key] = decodeURIComponent(value);
            }
            return result;
        }
    }

    public navigationInterceptor(event: RouterEvent): void {

        if (event instanceof NavigationStart) {
            this.blockUI.start('Carregando Informações');
            if (!this.global.loggedUser()) {
                this.global.params = this.getQueryParams(event.url);

                if (this.global.params) {
                    localStorage.setItem('params', JSON.stringify(this.global.params));
                }
                
                if (this.global.params && this.global.params.ref) {
                    localStorage.setItem('ref', this.global.params.ref);
                }
            }
            if (event.url == "/universidade") {
                if (this.global.loggedUser().client_id == 23) {
                    let token = sessionStorage.getItem("tokenLogin");
                    window.location.href = "https://universidade.bioritmo.com.br/active-directory?token=" + token;
                }
            }
        }

        if (event instanceof NavigationEnd) {
            this.blockUI.stop()
        }

        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.blockUI.stop()
        }

        if (event instanceof NavigationError) {
            this.blockUI.stop()
        }
    }

    public tokenLogin(token, brand = null) {
        return new Promise((resolve) => {
            this.blockUI.start("Token encontrado, efetuando Logon...")
            this.authService.loginToken(token, brand).subscribe(
                (data) => {
                    console.log("tokenLogin",data);
                    this.authService.setSessionToken(data)
                        .then((response) => {
                            sessionStorage.setItem("tokenLogin", token);
                            resolve(response);
                        })
                        .catch((err) => {
                            //this.isLoading = false;
                        });
                },
                (err) => {
                    console.log("tokenLogin",err);
                    //this.isLoading = false;
                    if (err.status === 404) {
                        //this.errorNotfound();
                    } else if (err.status = 401) {
                        //this.errorNotificate();
                    }
                }
            );
        });
    }

    public scrollTop(event) {
        window.scroll(0, 0);
    }
}
