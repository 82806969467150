import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-gain',
  templateUrl: './gain.component.html'
})

export class GainComponent {

  @Input() gain;

}
