import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { TranslateService } from '@ngx-translate/core';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            isolate: false
        })
    ],
    exports: [
      TranslateModule
    ],
    providers: [ TranslateService ]
})
export class TranslateSharedModule {

    constructor(translate: TranslateService) {
        translate.setDefaultLang('pt-br');
        translate.use('pt-br');

        // const browserLang = translate.getBrowserLang();
        // translate.use(browserLang.match(/en|es|pt-br/) ? browserLang : 'pt-br');
    }

 }
