import { Component } from '@angular/core';

@Component({
  selector: 'app-workplace-points',
  templateUrl: './workplace-points.component.html'
})

export class WorkplacePointsComponent {

}
