import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsapBaseService } from 'app/@core/lib/asap-base.service';
import swal from 'sweetalert2';
import { Global } from 'app/@core/lib/global';
import Swal from 'sweetalert2';

@Injectable()
export class ChannelService extends AsapBaseService {

    public isSubscriber = false;

    constructor(
        public http: HttpClient,
        public global: Global
    ) {
        super(http);
        this.basePath = "api/v2/admin";
        this.entityName = "channel";
        this.isSubscriber = false;
    };

    public channelLibrary(search,page) {
        if (page == null) page = 1;
        return this.createResource({search:search},{router:"videos",query:"page=" + page});
    }

    public channeGrouplLibrary(search,page) {
        if (page == null) page = 1;
        return this.createResource({search:search},{router:"videos",query:"page=" + page +'&group'});
    }

    public fileLibrary(search,page) {
        if (page == null) page = 1;
        return this.createResource({search:search},{router:"files",query:"page=" + page});
    }

    public fileGroupLibrary(search,page) {
        if (page == null) page = 1;
        return this.createResource({search:search},{router:"files",query:"page=" + page+'&group'});
    }

    public subscribe(channel_id, callback = null) {
        this.createResource({},{router:"subscribe/" + channel_id}).subscribe(() => {
            this.global.notification.success('Pronto', 'Inscrição efetuada com sucesso!')
            this.isSubscriber = true;
            if (callback) {
                callback();
            }
        });
    }

    public  like(video_id, like = true, type = 'video') {
        let data:any;

        data = (like) ? {like: 1} : {unlike: 1};
        data.type = type;

        return this.createResource(data,{router: 'like/' + video_id});
    }

    public  likev2(video_id, dados, type = 'video') {
        dados.type = type;
        return this.createResource(dados,{router: 'like/' + video_id});
    }
    

    public unsubscribe(channel_id, callback = null) {
        Swal.fire({
            type: "warning",
            text:"Você realmente deseja cancelar sua inscrição?",
            position: 'top-right',
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não"
        }).then(
            confirm => {
                if (confirm) {
                    this.createResource({},{router:"subscribe/" + channel_id}).subscribe(() => {
                        this.global.notification.info('Pronto', 'Você não está mais inscrito no Canal!')
                        this.isSubscriber = false;

                        if (callback) {
                            callback();
                        }
                    });
                }
            },
            cancel => {}
        );
        
    }

    public getPlaylists(user_id = null, type='home') {
        return this.getResources({router: 'playlist/' + user_id + '?type=' + type});
    }

    public getPlaylist(playlist_id, user_id) {
        return this.getResources({router: 'playlist/' + playlist_id + '/' + user_id});
    }

    public createPlaylist(playlist_name) {
        let data:any;
        data = {name:playlist_name}
        return this.createResource(data, {router: 'playlist'});
    }

    public deletePlaylist(playlist_id) {
        return this.deleteResource(null, {router: 'playlist/'+playlist_id});
    }

    public addVideoPlaylist(video_id, playlist_id) {
        let data: any;
        data = {video_id: video_id};
        console.log('VideoID ->', data.video_id);
        console.log('playlist_id ->', playlist_id);
        return this.createResource(data, {router: 'playlist/'+playlist_id});
    }

    public updateChannel(data) {
        return this.updateResource(data);
    }
}