import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

export class AsapBaseService extends BaseService {

    constructor(
        public http: HttpClient
    ) {
        super(http);
    };

    public getEntity(id) {
        return this.getResource(id);
    }

    public save(entity, params=null) {
        if (entity.id) {
            return this.updateResource(entity, params);
        }
        else {
            return this.createResource(entity, params);
        }
    }

    public delete(id) {
        return this.deleteResource(id);
    }

    public listAll(params) {
        return this.getResources(params);
    }
}