import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'asapClient'
})
export class AsapClientPipe implements PipeTransform {

    public static dictionary = {
        appName: 'Sistema EAD/ASAP',
        mainColor: "maroon",
        bgSidebar: "green",
        linkSidebar: "black",
        bgNavbar: "green",
        linkNavbar: "black",
        bgFooter: "green",
        linkFooter: "black",
        bgBtn: "green",
        textBtn: "black",
        bgSidebarCourse: "black",
        bgBtnCourse: "black",
        textBtnCourse: "black",
        textBtnCourseActive: "black",
        loginBG: 'https://images.pexels.com/photos/531880/pexels-photo-531880.jpeg?cs=srgb&dl=background-blur-clean-531880.jpg&fm=jpg',
        logoLight: 'https://va.medcom.id/2018/default/images/main/logo.png',
        logoDark: 'https://media.avvosites.com/upload/sites/3145/2018/03/thrift-mclemore-logo.png',
        logoFooter: 'https://www.shsu.edu/global/images/shsu.svg',
        emblem: 'https://rcpsg.ac.uk/images/svg/icon-graduation-cap.svg',
        bannerHome: 'https://static1.squarespace.com/static/54f87ad5e4b064cc65123213/t/54fb4747e4b03e45c6388dac/1425753930819/sursumcorda_porto.png?format=1500w',
        bannerPage: 'https://static1.squarespace.com/static/54f87ad5e4b064cc65123213/t/54fb4747e4b03e45c6388dac/1425753930819/sursumcorda_porto.png?format=1500w',

        intro: 'Você está no Sistema EAD/ASAP',
    };

    public static setDictionary(dic) {
        for (const key in dic) {
            AsapClientPipe.dictionary[key] = dic[key];
        }
    }

    transform(value: any, args?: any): any {
        if (AsapClientPipe.dictionary[value]) {
            return AsapClientPipe.dictionary[value];
        }
        return value;
    }

}
