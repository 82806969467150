import { Component, Input, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'app-challenges-charts',
    templateUrl: 'challenges-charts.component.html',
    styleUrls: ['./challenges-charts.component.scss']
})

export class ChallengesChartsComponent {

  @Input() data;
  @Input() loading;  
  @ViewChild(BaseChartDirective, {static:false}) baseChart: BaseChartDirective;
  legendData: any;
	// lineChart
  public lineChartData:Array<any> = [
    {data: [0, 20, 10, 10, 0], label: 'Pontos por Dia'}
  ];

  private getLegendCallback = (function(self) {
    function handle(chart) {
      return chart.legend.legendItems;
    }
    return function(chart) {
      return handle(chart);
    }
  })(this);

  public lineChartLabels:Array<any> = ['1', '2', '3', '4', '5'];
  public lineChartOptions:any = {
    global: {
     maintainAspectRatio: true
   },
   scales: {
     yAxes: [{
       gridLines: {
         display:false
       },
       ticks: {
         display: false
       }
     }],
     xAxes: [{
       scaleLabel: {
         display: true,
       },
       gridLines: {
         display:false
       },
       ticks: {
         display: true,
         fontSize: 14,
         autoSkip: false
       }
     }]
   },
   legendCallback: this.getLegendCallback,
   legend: {
     display: false
   },
   tooltips: {
     callbacks: {
       title: function(tooltipItem, data) {
         return data['labels'][tooltipItem[0]['index']];
       },
       label: function(tooltipItem, data) {
         return data['datasets'][0]['data'][tooltipItem['index']];
       }
     },
     backgroundColor: 'rgba(0,0,0, 0.7)',
     titleFontSize: 18,
     titleFontColor: '#f2f2f2',
     bodyFontColor: '#ccc',
     bodyFontSize: 20,
     xPadding: 10,
     yPadding: 10,
     displayColors: false
   },    
 };

  public lineChartColors:Array<any> = [
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  public lineChartLegend:boolean = true;
  public lineChartType:string = 'line';
 
  public randomize():void {
    let _lineChartData:Array<any> = new Array(this.lineChartData.length);
    for (let i = 0; i < this.lineChartData.length; i++) {
      _lineChartData[i] = {data: new Array(this.lineChartData[i].data.length), label: this.lineChartData[i].label};
      for (let j = 0; j < this.lineChartData[i].data.length; j++) {
        _lineChartData[i].data[j] = Math.floor((Math.random() * 100) + 1);
      }
    }
    this.lineChartData = _lineChartData;
  }
 
  // events
  public chartClicked(e:any):void {
    console.log(e);
  }
 
  public chartHovered(e:any):void {
    console.log(e);
  }

  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.getData(this.data);
  }


  getData(result) {
    let views = [];
    this.lineChartLabels = [];
    for (let item of result) {
      let date = item.date.substring(6, 10).split("-");
      views.push(item.views)
    }
    this.lineChartData = [
      {data: views, label: 'Pontos por Dia'}
    ]
    this.lineChartLabels = ['dia 1', 'dia 2', 'dia 3', 'dia 4', 'dia 5'];
    this.loading = false;
    this.cdRef.detectChanges();
    this.legendData = this.baseChart.chart.generateLegend();  
  }

  updateDataset(datasetIndex) {
    let chart = this.baseChart.chart
    let meta = chart.getDatasetMeta(datasetIndex);
    meta.hidden = meta.hidden === null? !chart.data.datasets[datasetIndex].hidden : null;
    chart.update();
  };

}
