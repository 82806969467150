import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TrailHomeService } from 'app/services/home-smart/trails-home.service';
import { OwlCarousel } from 'ngx-owl-carousel';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  providers: [TrailHomeService]
})
export class CoursesComponent implements OnInit {

  @ViewChild('owlElement', {static:false}) owlElement: OwlCarousel
  public training;
  public loading;

  options = {
    items: 1,
    loop: true,
    center: true,
    margin: 0,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    URLhashListener: true,
    autoplay:true,
    autoplayTimeout:3000,
    autoplayHoverPause:true,
    nav: false,
    dots: false
  };


  constructor(
    public trailService: TrailHomeService
  ) {

  }

  ngOnInit() {
    this.getAllTrail();
  }

  getAllTrail() {
    this.trailService.getResources().subscribe( res => {
      this.training = res.data.trilhas;
      this.loading = false;
      console.log('Trilhas', this.training);
    }, err => {
      this.loading = false;
    })
  }

}
