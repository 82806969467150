import { Component } from '@angular/core';

@Component({
  selector: 'app-workplace-quick-access',
  templateUrl: './workplace-quick-access.component.html'
})

export class WorkplaceQuickAccessComponent {

}
