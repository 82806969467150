import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TrailService } from '../../../@core/services/trail.service';
import { Trail } from '../../../@core/models/trail';

@Component({
    selector: 'dialog-challenges-details-congrulations',
    templateUrl: 'challenges-details-congrulations.component.html',
    styleUrls: ['./challenges-details-congrulations.component.scss'],
    providers: [ Trail, TrailService]
  })
  export class ChallengesDetailsCongrulationsDialog {
  
    loading = true;
    
    constructor(
      public dialogRef: MatDialogRef<ChallengesDetailsCongrulationsDialog>,
      private _trailService: TrailService,
      @Inject(MAT_DIALOG_DATA) public data
    ) {}
  
    onNoClick(): void {
      this.dialogRef.close();
    }
    
    ngOnInit() {
        this._trailService.challengeCloseModal(this.data).subscribe( res=> {
            this.loading = false;
        })
    }
  
  }