import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-list-articles',
  templateUrl: './list-articles.component.html',
  styleUrls: ['../../app.component.css', './list-articles.component.css']
})

export class ListArticlesComponent {

  // @Input() list;
  //
  // constructor(
  //   private _sanitizer: DomSanitizer
  // ) { }
  //
  // getUrl(video) {
  //   let url = video.split("watch?v=");
  //   return this._sanitizer.bypassSecurityTrustResourceUrl("http://img.youtube.com/vi/"+url[1]+"/mqdefault.jpg");
  // }

}
