export const environment = {
  production: false,
  apiUrl: 'https://apiv3.plataformaasap.com.br',
  baseUrl: 'https://apiv3.plataformaasap.com.br',
  // apiUrl: 'https://api.hmg.tecnologiaasap.com.br',
  // baseUrl: 'https://api.hmg.tecnologiaasap.com.br',
  filestack: {
    key: 'AD5oDjsJaTJOzLe1Llj9mz'
  },
  pusher: {
    key: 'eaf41725f3b027147953',
  },
  firebase: {
    apiKey: 'AIzaSyDcVaYzLKZKPFwL31F099rp3p8s30XYlQ0',
    authDomain: 'universidade-smart-fit.firebaseapp.com',
    databaseURL: 'https://universidade-smart-fit.firebaseio.com',
    projectId: 'universidade-smart-fit',
    storageBucket: 'universidade-smart-fit.appspot.com',
    messagingSenderId: '706681009423'
  }
};
