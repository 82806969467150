import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { MomentModule } from 'angular2-moment';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

// call translate lazy
import { TranslateLazyModule } from '../translate/translate-lazy.module';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OwlModule } from 'ngx-owl-carousel';

import { NavbarComponent } from './navbar/navbar.component';
import { NavbarMobileComponent } from './navbar-mobile/navbar-mobile.component';
import { SideMenuComponent } from './sidemenu/sidemenu.component';
import { FooterComponent } from './footer/footer.component';
import { PointsComponent } from './points/points.component';
import { GainComponent } from './gain/gain.component';
import { MedalsComponent } from './medals/medals.component';
import { ContributorsComponent } from './contributors/contributors.component';
import { HighlightsComponent } from './highlights/highlights.component';
import { TrainingAndProductsComponent } from './training-and-products/training-and-products.component';
import { MenuForumComponent } from './menu-forum/menu-forum.component';
import { ChallengesComponent } from './challenges/challenges.component';
import { ChallengesDetailsCongrulationsDialog } from './challenges/challenges-details-congrulations/challenges-details-congrulations.component';
import { ChallengesDetailsDialog } from './challenges/challenges-details-modal/challenges-details-modal.component';
import { ChallengesChartsComponent } from './challenges-charts/challenges-charts.component';
import { PlaylistComponent } from './playlist/playlist.component';
import { ListArticlesComponent } from './list-articles/list-articles.component';
import { ListTechnicalComponent } from './list-technical/list-technical.component';
import { RankingComponent } from './ranking/ranking.component';
import { ProgressComponent } from './progress/progress.component';
import { HighlightSearchPipe } from './pipes/index';
import { MyPageBloxComponent } from './my-page-blox/my-page-blox.component';

// Tutor Panel
import { SideMenuTutorComponent } from './sidemenu-tutor/sidemenu-tutor.component';

// Workplace components
import { WorkplaceMenuComponent } from './workplace-menu/workplace-menu.component';
import { WorkplaceSearchComponent } from './workplace-search/workplace-search.component';
import { WorkplaceStoriesComponent } from './workplace-stories/workplace-stories.component';
import { WorkplaceQuickAccessComponent } from './workplace-quick-access/workplace-quick-access.component';
import { WorkplaceSpotlightsComponent } from './workplace-spotlights/workplace-spotlights.component';
import { WorkplacePointsComponent } from './workplace-points/workplace-points.component';
import { WorkplaceRankingComponent } from './workplace-ranking/workplace-ranking.component';
import { WorkplaceForumComponent } from './workplace-forum/workplace-forum.component';
import { WorkplaceBloxModalComponent } from './workplace-blox-modal/workplace-blox-modal.component';

// Feeds

// Video Place components
import { SideMenuVideoComponent } from './sidemenu-video/sidemenu-video.component';
import { PipesModule } from 'app/@core/pipes/pipe.module';
import { MaterialModule } from 'app/material.module';
import { WorkplaceEditalComponent } from './workplace-edital/workplace-edital.component';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CoursesModule } from 'app/public/courses/courses.module';
import { CoursesComponent } from 'app/public/courses/courses.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { AsapUploadModule, AsapUploadComponent } from 'asap-upload';
import { AsapSkeletonModule } from 'asap-skeleton';
import { AsapImagePipeModule } from 'asap-image-pipe';
import { AsapLimitToModule } from 'asap-limit-to';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ChartsModule,
    MomentModule,
    // UploadModule,
    AsapUploadModule,
    TranslateLazyModule,
    MatTooltipModule,
    MatIconModule,
    OwlModule,
    Ng2ImgMaxModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    MaterialModule,
    InfiniteScrollModule,
    NgxMasonryModule,
    AsapSkeletonModule,
    AsapImagePipeModule,
    AsapLimitToModule
  ],
  declarations: [
    NavbarComponent,
    NavbarMobileComponent,
    SideMenuComponent,
    FooterComponent,
    PointsComponent,
    GainComponent,
    MedalsComponent,
    ContributorsComponent,
    HighlightsComponent,
    TrainingAndProductsComponent,
    MenuForumComponent,
    PlaylistComponent,
    ListArticlesComponent,
    ListTechnicalComponent,
    ChallengesComponent,
    ChallengesDetailsCongrulationsDialog,
    ChallengesDetailsDialog,
    ChallengesChartsComponent,
    RankingComponent,
    ProgressComponent,
    HighlightSearchPipe,
    MyPageBloxComponent,
    SideMenuTutorComponent,
    WorkplaceMenuComponent,
    WorkplaceSearchComponent,
    WorkplaceStoriesComponent,
    WorkplaceQuickAccessComponent,
    WorkplaceSpotlightsComponent,
    WorkplacePointsComponent,
    WorkplaceRankingComponent,
    WorkplaceForumComponent,
    WorkplaceBloxModalComponent,
    SideMenuVideoComponent,
    WorkplaceEditalComponent,
    CoursesComponent
  ],
  exports: [
    NavbarComponent,
    NavbarMobileComponent,
    SideMenuComponent,
    FooterComponent,
    PointsComponent,
    GainComponent,
    MedalsComponent,
    ContributorsComponent,
    HighlightsComponent,
    TrainingAndProductsComponent,
    MenuForumComponent,
    PlaylistComponent,
    ListArticlesComponent,
    ListTechnicalComponent,
    ChallengesComponent,
    ChallengesDetailsCongrulationsDialog,
    ChallengesDetailsDialog,
    ChallengesChartsComponent,
    RankingComponent,
    ProgressComponent,
    HighlightSearchPipe,
    MyPageBloxComponent,
    AsapUploadComponent,
    TranslateLazyModule,
    SideMenuTutorComponent,
    WorkplaceMenuComponent,
    WorkplaceSearchComponent,
    WorkplaceStoriesComponent,
    WorkplaceQuickAccessComponent,
    WorkplaceSpotlightsComponent,
    WorkplacePointsComponent,
    WorkplaceRankingComponent,
    WorkplaceForumComponent,
    WorkplaceBloxModalComponent,
    SideMenuVideoComponent,
    WorkplaceEditalComponent,
    CoursesComponent,
    AsapImagePipeModule
  ],
  entryComponents: [
    ChallengesDetailsCongrulationsDialog,
    ChallengesDetailsDialog,
  ]
})
export class SharedModule { }
