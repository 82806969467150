import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html'
})

export class PlaylistComponent implements OnChanges {

  @Input() list;

  public page: number;
  public videoArray: any;

  item_final: number;

  constructor(
    private _sanitizer: DomSanitizer
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.bootstrap();
  }

  bootstrap() {
    console.log('PlaylistComponent: bootstrap');

    this.page = 0;
    this.videoArray = [];

    this.insertVideosList();
  }

  insertVideosList() {
    if (this.list) {
      const offset = this.page * 3;
      const end = offset + 3;
      const partial = this.list.slice(offset, end);

      this.item_final = end;

      this.videoArray.push(...partial);
      this.page++;
    }
  }

  getUrl(video) {
    if (video.image === null) {
      const url = video.file.split('watch?v=');
      return this._sanitizer.bypassSecurityTrustResourceUrl('http://img.youtube.com/vi/' + url[1] + '/mqdefault.jpg');
    } else {
      return video.image;
    }
  }

}
