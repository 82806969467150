import {Component, OnInit} from '@angular/core';

import { MenuService } from 'app/services/menu.service';
import { GlobalService } from 'app/services/global.service';

@Component({
  selector: 'app-workplace-menu',
  templateUrl: './workplace-menu.component.html',
  providers: [MenuService]
})

export class WorkplaceMenuComponent implements OnInit {

  menuArray: any;
  public loading = true;

  constructor(
    public _menuService: MenuService,
    public global: GlobalService
  ) {
  }

  ngOnInit() {
    this.getMenu();
  }

  getMenu() {
    this._menuService.getResources().subscribe(res => {
        if (this.global.loggedUser().client_id == 23) {
          this.menuArray = res.data.map(item => {
              item.list = item.list.map(menu => {
                  if (menu.url == "https://universidade.bioritmo.com.br/active-directory") {
                      let token = sessionStorage.getItem("tokenLogin");
                      menu.url = "https://universidade.bioritmo.com.br/active-directory?token=" + token;
                  }
                  else if (menu.url == "https://portal.smartfit.com.br/universidade") {
                      menu.url = "https://universidade.smartfit.com.br";
                  }
                  return menu;
              });
              return item;
          });
        }
        else {
            this.menuArray = res.data;
        }
        console.log("DEBUG", this.global.loggedUser().client_id, this.menuArray);
        this.loading = false;
    });
  }

}
