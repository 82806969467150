import { Component, ViewChild } from '@angular/core';
import { OwlCarousel } from 'ngx-owl-carousel';

@Component({
  selector: 'app-my-page-blox',
  templateUrl: './my-page-blox.component.html',
  styleUrls: ['../../app.component.css', './my-page-blox.component.css'],
})

export class MyPageBloxComponent {

  @ViewChild('owlElement', {static:false}) owlElement: OwlCarousel

  options={
    items:3,
    loop:true,
    center:true,
    margin:10,
    URLhashListener:true,
    autoplayHoverPause:true,
    startPosition: 'URLHash',
    nav: false,
    dots: false
      }

}
