import { Http, Headers, Response, RequestOptions } from '@angular/http';
import "rxjs/add/operator/map";
import { environment } from '../../../environments/environment';


export class BaseService {
  constructor(
    protected http: Http,
    protected basePath: string,
    protected entityName: string) {}

  protected host: string = environment.apiUrl
  protected token = sessionStorage.getItem('token')
  protected headers = new Headers({
    'Content-Type': 'application/json',
    'Authorization': this.token,
    'Access-Control-Allow-Origin': '*'
  })

  protected options = new RequestOptions({
    headers: this.headers
  })

  getResources(params = {}) {
    return this.http.get(this.resourceUrl(null, params), this.options).map((res: Response) => res.json())
  }

  getResource(id, params = {}) {
    return this.http.get(this.resourceUrl(id, params), this.options).map((res: Response) => res.json())
  }

  createResource(data, params = {}) {
    return this.http.post(this.resourceUrl(null, params), JSON.stringify(data), this.options).map((res: Response) => res.json())
  }
  
  updateResource(data, params = {}) {
    return this.http.put(this.resourceUrl(data.id, params), JSON.stringify(data), this.options).map((res: Response) => res.json())
  }

  deleteResource(id) {
    return this.http.delete(this.resourceUrl(id), this.options).map((res: Response) => res.status)
  }

  resourceUrl(id = null, params = {}) {

    const endpoint = [
      this.host,
      this.basePath,
      this.urlParameters(params),
      this.entityName,
      id
    ].filter(element => element != false).join('/').replace(/\/$/, '')

    return endpoint + this.queryString(params)
  }

  protected urlParameters(params) {
    var urlParameters = []

    for(var placeholder in params)
      if (/.*_id$/.test(placeholder))
        urlParameters.push(`${placeholder}/${params[placeholder]}`)

    return urlParameters.join('/')
  }

  protected queryString(params) {
    if(params.query){
      return `?${params.query}`
    }else if(params.router){
      return `/${params.router}`    
    }else {
      return ''
    }
  }


}
