import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';


export class BaseService {

    protected host: string = environment.apiUrl;

    public basePath: string;
    public entityName: string;

    protected token = sessionStorage.getItem('token');
        
    constructor(
        public http: HttpClient
    ) {

    };

    protected headers(): HttpHeaders {
        let result:any = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'CompanyName': 'ASAP'
        };

        this.token = sessionStorage.getItem('token');

        if (this.token) {
            result.Authorization = this.token;
        }
        
        return new HttpHeaders(result);
    };


    protected options(): any {
        const headerOptions = this.headers();
        return {headers: headerOptions};
    }

    getResources(params = {}) {
       return this.http.get(this.resourceUrl(null, params), this.options());
    }

    getResource(id, params = {}) {
        return this.http.get(this.resourceUrl(id, params), this.options());
    }

    createResource(data, params = {}) {
        return this.http.post(this.resourceUrl(null, params), data, this.options());
    }
    
    updateResource(data, params = {}) {
        return this.http.put(this.resourceUrl(data.id, params), data, this.options());
    }

    deleteResource(id, params = {}) {
        return this.http.delete(this.resourceUrl(id, params), this.options());
    }

    resourceUrl(id = null, params = {}) {

        const endpoint = [
            this.host,
            this.basePath,
            this.urlParameters(params),
            this.entityName,
            id
        ].filter(element => element != false).join('/').replace(/\/$/, '');

        let url = endpoint + this.queryString(params);

        let client_id:any = sessionStorage.getItem("client");
        if (client_id) {
            if (url.indexOf("?") >= 0) url = url + "&"; else url = url + "?";
            url = url + "client_id=" + client_id;
        }

        return url;
    }
    
    protected urlParameters(params) {
        var urlParameters = []

        for (var placeholder in params) {
            if (/.*_id$/.test(placeholder)) {
                urlParameters.push(`${placeholder}/${params[placeholder]}`)
            }
        }

        return urlParameters.join('/')
    }

    protected queryString(params) {
        let result = "";
        if (params.router) {
            result = `/${params.router}`;
        }
        if (params.query) {
            if (result != "") {
                result = result + `?${params.query}`
            }
            else {
                result = `?${params.query}`
            }
        }
        return result;
    }

}
