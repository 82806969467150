import { Component, ViewChild, OnInit } from '@angular/core';
import { OwlCarousel } from 'ngx-owl-carousel';
import { ChannelService } from 'app/services/channel.service';

@Component({
  selector: 'app-workplace-stories',
  templateUrl: './workplace-stories.component.html',
  providers: [ChannelService]
})

export class WorkplaceStoriesComponent implements OnInit {

  @ViewChild('owlElement', {static:false}) owlElement: OwlCarousel
  public channelArray: any;
  public loading = true;

  options = {
    loop: true,
    center: false,
    margin: 0,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    URLhashListener: true,
    autoplayHoverPause: true,
    dots: false,
    responsive:{
        0:{
            items: 2,
            nav: false
        },
        1024:{
            items: 3.5,
            nav: false
        }
    }
  };

  constructor(
    public _channelService: ChannelService
  ) {

  }

  ngOnInit() {
    this.getChannels();
  }

  getChannels() {
    this._channelService.getResources({query: 'type=recommended'})
      .subscribe((res: any) => {
        console.log('getChannels', res.data);
        this.channelArray = res.data;
        this.loading = false;
        console.log('channelArray', this.channelArray);
      });
  }

}
