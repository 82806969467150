import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['../../app.component.css', './ranking.component.css']
})

export class RankingComponent {

  @Input() ranking;
  @Input() forum;

}
