import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
// import { AngularFireModule } from 'angularfire2';
// import { AngularFirestoreModule } from 'angularfire2/firestore';
// import { AngularFireStorageModule } from 'angularfire2/storage';

import { SafePipeModule } from 'safe-pipe';

import { environment } from '../environments/environment';

import { AuthServiceConfig } from 'angular5-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angular5-social-login';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { RoleGuardService } from './services/role-guard.service';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask/src/currency-mask.config';
import { ClientInterceptor } from './@core/interceptors/client-interceptor';
import { SimpleNotificationsModule } from 'angular2-notifications';


export function getAuthServiceConfigs() {
  const config = new AuthServiceConfig([
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider('214373356016835')
    },
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider('690940757822-jrr093pf2qieg1rd27erh5kqokp2kcun.apps.googleusercontent.com')
    }
  ]);
  return config;
}

import { AppComponent } from './app.component';

import { SharedModule } from './shared/shared.module';
import { AuthComponent } from './auth/auth.component';
import { PublicComponent } from './public/public.component';
import { TutorPanelComponent } from './tutor-panel/tutor-panel.component';

// Novos ambientes
import { WorkplaceComponent } from './workplace/workplace.component';
import { ChannelsComponent } from './channels/channels.component';
import { ExtensionComponent } from './extension/extension.component';
import { AssessmentComponent } from './assessment/assessment.component';

import { AppRoutes } from './app.routing';

// call translation root
import { TranslateSharedModule } from './translate/translate.module';
import { TutorService } from './services/tutor/tutor.service';
import { PipesModule } from './@core/pipes/pipe.module';
import { MaterialModule } from './material.module';
import { FileLibraryComponent } from './workplace/workplace-home/file-library/file-library.component';
import { ChannelLibraryComponent } from './workplace/workplace-home/channel-library/channel-library.component';
import { SpinnerModule } from './modules/shared/spinner/spinner.module';
import { OwlModule } from 'ngx-owl-carousel';
import { PortalRouteService } from './services/portal-route.service';
import { GlobalService } from './services/global.service';
import { HomeRouteService } from './services/home-route.service';
import { AvatarModule } from 'ngx-avatar';
import { MainService } from './services/main.service';
import { WorkplaceSidemenuComponent } from './shared/workplace-sidemenu/workplace-sidemenucomponent';
import { BlockUIModule } from 'ng-block-ui';
import { AsapViewerModule } from 'asap-viewer';
import { AsapCrudModule } from 'asap-crud';
import { AsapDocumentsModule } from 'asap-documents';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AsapImagePipeModule } from 'asap-image-pipe';
import { AsapFeedModule } from 'asap-feed';
import { RedirectComponent } from './redirect.component';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.'
};

@NgModule({
    imports: [
      CommonModule,
      BrowserAnimationsModule,
      FormsModule,
      AsapFeedModule,
      RouterModule.forRoot(AppRoutes),
      HttpModule,
      MaterialModule,
      SharedModule,
      HttpClientModule,
      CurrencyMaskModule,
      SafePipeModule,
      TranslateSharedModule,
      AngularFireModule.initializeApp(environment.firebase),
      AngularFirestoreModule,
      AngularFireStorageModule,
      PipesModule,
      AsapImagePipeModule,
      BlockUIModule.forRoot(),
      SimpleNotificationsModule.forRoot({
          timeOut: 3000,
          showProgressBar: false,
          // theClass:'mt-10',
          pauseOnHover: true,
          clickToClose: true,
          position: ['top', 'right']
      }),
      SpinnerModule,
      OwlModule,
      AvatarModule,
      AsapCrudModule,
      AsapViewerModule,
      AsapDocumentsModule
    ],
    declarations: [
      AppComponent,
      AuthComponent,
      PublicComponent,
      TutorPanelComponent,
      WorkplaceComponent,
      ChannelsComponent,
      ExtensionComponent,
      AssessmentComponent,
      FileLibraryComponent,
      ChannelLibraryComponent,
      WorkplaceSidemenuComponent,
      RedirectComponent
    ],
    exports: [
      MaterialModule,
      SharedModule,
      SafePipeModule
    ],
    providers: [
      GlobalService,
      AuthService,
      PortalRouteService,
      HomeRouteService,
      AuthGuardService,
      RoleGuardService,
      AuthService,
      TutorService,
      MainService,
      {
        provide: CURRENCY_MASK_CONFIG,
        useValue: CustomCurrencyMaskConfig
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ClientInterceptor,
        multi: true
      },
      {
        provide: AuthServiceConfig,
        useFactory: getAuthServiceConfigs
      }
    ],
    bootstrap: [
      AppComponent
    ]
})

export class AppModule {
  constructor(private authService: AuthService, private route: Router) {
    this.authService.syncActiveClient('20');
  }
}
