import {Injectable} from '@angular/core';
import {ServicesInterface} from '../interfaces/services.interface';
import {Observable} from 'rxjs/Observable';
import {Trail} from '../models/trail';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {LibraryHelper} from '../helpers/library.helper';

@Injectable()
export class TrailService implements ServicesInterface {

  protected arrayModel: Trail[] = [];

  constructor(protected _trailModel: Trail, private http: HttpClient) {
  }

  collection(): Promise<any> {
    const _this = this;
    this.arrayModel = [];
    return new Promise<any>((resolve, reject) => {
      _this.findAll().subscribe(res => {
        for (const trail of res.data) {
          _this.arrayModel.push(_this._trailModel.populate({
            id: trail.id,
            name: trail.nome_pasta,
            role: trail.role,
            date: trail.created_at.date,
            tag: trail.tag,
          }));
        }
        resolve(_this.arrayModel);
      }, err => {
        reject(err);
      })
    })
  }

  findHome(): Observable<any> {
    return this._trailModel.findHome();
  }

  findAll(): Observable<any> {
    return this._trailModel.findAll();
  }

  findAllClient(): Observable<any> {
    return this._trailModel.findAllClient();
  }

  findAllAvatar(): Observable<any> {
    return this._trailModel.findAllAvatar();
  }

  findAvatar(data: any): Observable<any> {
    return this._trailModel.findAvatar(data);
  }

  avatarUnlock(data: any): Observable<any> {
    return this._trailModel.avatarUnlock(data);
  }

  findLevels(data: any): Observable<any> {
    return this._trailModel.findLevels(data);
  }
  
  findAvatarScore(data: any): Observable<any> {
    return this._trailModel.findAvatarScore(data);
  }

  findTrainingScore(data: any): Observable<any> {
    return this._trailModel.findTrainingScore(data);
  }

  aceptChallenge(data: any): Observable<any> {
    return this._trailModel.aceptChallenge(data);
  }

  getChallenge(data: any): Observable<any> {
    return this._trailModel.getChallenge(data);
  }

  aceptChallengeModules(data: any): Observable<any> {
    return this._trailModel.aceptChallengeModules(data);
  }

  getChallengeModules(data: any): Observable<any> {
    return this._trailModel.getChallengeModules(data);
  }

  getVideo(data: any): Observable<any> {
    return this._trailModel.getVideo(data);
  }

  getAllVideo(data: any): Observable<any> {
    return this._trailModel.getAllVideo(data);
  }

  challengeCloseModal(data: any): Observable<any> {
    return this._trailModel.challengeCloseModal(data);
  }

  searchLevels(data: any): Observable<any> {
    return this._trailModel.searchLevels(data);
  }

  findOneBy(data: any): Observable<any> {
    return undefined;
  }

  findBy(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._trailModel.findBy(id)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
        })
    })
  }

  filterBy(data: any[]): Observable<any> {
    return this._trailModel.filterBy(data);
  }

  persist(trail: Trail): Observable<any> {
    return this._trailModel.persist(trail);
  }

  delete(id: number): Observable<any> {
    return this._trailModel.delete(id);
  }

  setStatus(status: number, id: number): Observable<any> {
    return this._trailModel.setStatus({status: status}, id);
  }

  saveData(trail: Trail): Observable<any> {
    return this._trailModel.saveData(trail);
  }

  update(trail: Trail): Observable<any> {
    return this._trailModel.update(trail);
  }

  persistTrailConfiguratorByGroupFilter(data: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/admin/grupo/has/trail/filter', data, LibraryHelper.getHeaders());
  }

  persistTrailConfiguratorByFilterOnly(data: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/admin/trail/has/filter', data, LibraryHelper.getHeaders());
  }

  persistTrailConfiguratorByUserGroup(data: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/admin/trail/config/users', data, LibraryHelper.getHeaders());
  }

  persistTrailConfiguratorByUserOnly(data: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/admin/trail/has/user', data, LibraryHelper.getHeaders());
  }

  folderUserShared(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/shared/folder/admin', LibraryHelper.getHeaders());
  }



}
