import { Component } from '@angular/core';

@Component({
  selector: 'app-medals',
  templateUrl: './medals.component.html'
})

export class MedalsComponent {

}
