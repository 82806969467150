import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight'
})

export class HighlightSearchPipe implements PipeTransform {

    transform(value: any, args: any): any {
        var re = new RegExp(args, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
        if(value == null ){
            return value
        } else {
            return value.replace(re, "<mark>" + args + "</mark>");
        }
    }
}