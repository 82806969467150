import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Component } from "@angular/core";
import { environment } from 'environments/environment';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, ActivatedRoute } from '@angular/router'
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { GlobalService } from "./services/global.service";
import { AuthService } from "./services/auth.service";

@Component({
    selector: 'app-redirect',
    template: ``,
    providers: [ AuthService ]
})
export class RedirectComponent {

    public params;

    constructor(
        private router: Router,
        private global: GlobalService,
        private authService: AuthService
    ) {
        let params = localStorage.getItem("params");
        localStorage.removeItem("params");
        if (params) {
            this.params = JSON.parse(params);
            if (this.params.token) {
                this.tokenLogin(this.params.token);
            }
            else {
                router.navigate(["/active-directory"]);
            }
        }
        else {
            router.navigate(["/active-directory"]);
        }
    }

    public tokenLogin(token) {
        let data = {token:token};
        console.log("tokenLogin",data);
        this.authService.setSessionToken(data)
            .then((response) => {
                sessionStorage.setItem("tokenLogin", token);
                if (this.params.url) {
                    this.router.navigate([this.params.url], { queryParams: { subs: this.params.subs }});
                }
            })
            .catch((err) => {
                //this.isLoading = false;
            });
    }

    public scrollTop(event) {
        window.scroll(0, 0);
    }
}
