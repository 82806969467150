import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['../../app.component.css', './highlights.component.css']
})

export class HighlightsComponent {

  @Input() trail;
  
}
