import { Component, OnInit } from '@angular/core';
import { Global } from 'asap-crud';
declare const $: any;

@Component({
  selector: 'app-workplace-sidemenu',
  templateUrl: './workplace-sidemenu.component.html'
})

export class WorkplaceSidemenuComponent implements OnInit {
    constructor(
      public global: Global 
    ){}
    ngOnInit() {

      
      $(document).ready(function () {

        $('#sidemenuCollapse').on('click', function () {
          $('.sidemenu, .content, .overlay').toggleClass('active');
        });

        $('.overlay').on('click', function () {
          $('.sidemenu, .content, .overlay').removeClass('active');
        });

        $('.components').on('click', function () {
          $('.sidemenu, .content, .overlay').removeClass('active');
        });

        $('.navbar-brand').on('click', function () {
          $('.sidemenu, .content, .overlay').removeClass('active');
        });

        $('.triangle').on('click', function () {
          $('.sidemenu, .content, .overlay').removeClass('active');
        });

      });

  }

}
