
import { Routes, RouterModule } from '@angular/router';

import { AuthComponent } from './auth/auth.component';
import { PublicComponent } from './public/public.component';
import { TutorPanelComponent } from './tutor-panel/tutor-panel.component';

import { WorkplaceComponent } from './workplace/workplace.component';
import { ChannelsComponent } from './channels/channels.component';
import { ExtensionComponent } from './extension/extension.component';
import { AssessmentComponent } from './assessment/assessment.component';

import { AuthGuardService } from './services/auth-guard.service';
import { FileLibraryComponent } from './workplace/workplace-home/file-library/file-library.component';
import { ChannelLibraryComponent } from './workplace/workplace-home/channel-library/channel-library.component';
import { PortalRouteService } from './services/portal-route.service';
import { HomeRouteService } from './services/home-route.service';
import { AsapFeedComponent, SingleComponent } from 'asap-feed';
import { AppComponent } from 'asap-feed/lib/asap-feed.component';
import { RedirectComponent } from './redirect.component';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'active-directory',
        pathMatch: 'full',
    },
    {
      path: 'redirect',
      component: RedirectComponent,
    },
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: 'active-directory',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./auth/active-directory/active-directory.module').then(m => m.ActiveDirectoryModule)
            },
            {
                path: 'login',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./auth/login/login.module').then(m => m.LoginModule)
            },
            {
                path: 'recovery',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./auth/recovery/recovery.module').then(m => m.RecoveryModule)
            }
        ]
    },
    {
        path: '',
        canActivate: [AuthGuardService, HomeRouteService],
        component: PublicComponent,
        children: [
            {
                path: 'universidade',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/home/home.module').then(m => m.HomeModule)
            },
            {
                path: 'home',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/home/home.module').then(m => m.HomeModule)
            },
            {
                path: 'training',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/training/training.module').then(m => m.TrainingModule)
            },
            {
                path: 'development',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/development/development.module').then(m => m.DevelopmentModule)
            },
            {
                path: 'influencers',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/influencers/influencers.module').then(m => m.InfluencersModule)
            },
            {
                path: 'influencers-section',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/influencers-section/influencers-section.module').then(m => m.InfluencersSectionModule)
            },
            {
                path: 'videos',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/videos/videos.module').then(m => m.VideosModule)
            },
            {
                path: 'articles',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/articles/articles.module').then(m => m.ArticlesModule)
            },
            {
                path: 'forum',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/forum/forum.module').then(m => m.ForumModule)
            },
            {
                path: 'my-page',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/my-page/my-page.module').then(m => m.MyPageModule)
            },
            {
                path: 'my-page-unit',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/unit-leader/unit-leader.module').then(m => m.UnitLeaderModule)
            },
            {
                path: 'notice',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/notice/notice.module').then(m => m.NoticeModule)
            },
            {
                path: 'welcome',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/welcome/welcome.module').then(m => m.WelcomeModule)
            },
            {
                path: 'search',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/search/search.module').then(m => m.SearchModule)
            },
            {
                path: 'technical',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/technical/technical.module').then(m => m.TechnicalModule)
            },
            {
                path: 'ecosystem',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/technical/technical.module').then(m => m.TechnicalModule)
            },
            {
                path: 'clients-plus',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/technical/technical.module').then(m => m.TechnicalModule)
            },
            {
                path: 'contribuitors',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/contributors/contributors.module').then(m => m.ContributorsModule)
            },
            {
                path: 'notifications',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/notifications/notifications.module').then(m => m.NotificationsModule)
            },
            {
                path: 'helpdesk',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/helpdesk/helpdesk.module').then(m => m.HelpdeskModule)
            },
            {
                path: 'management',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/management/management.module').then(m => m.ManagementModule)
            },
            {
                path: 'journey',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/journey/journey.module').then(m => m.JourneyModule)
            },
            {
                path: 'voucher',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./public/voucher/voucher.module').then(m => m.VoucherModule)
            },
            {
                path: 'file-library',
                canLoad: [AuthGuardService],
                component: FileLibraryComponent,
                canActivate: [PortalRouteService]
            },
            {
                path: 'channel-library',
                canLoad: [AuthGuardService],
                component: ChannelLibraryComponent,
                canActivate: [PortalRouteService]
            }
        ]
    },
    {
        path: '',
        component: TutorPanelComponent,
        children: [
            {
                path: 'tutor',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./tutor-panel/courses/tutor-panel-courses.module').then(m => m.TutorPanelCoursesModule)
            },
            {
                path: 'tutor/classes/:id',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./tutor-panel/classes/tutor-panel-classes.module').then(m => m.TutorPanelClassesModule)
            },
            {
                path: 'tutor/subscribers/:id',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./tutor-panel/subscribers/tutor-panel-subscribers.module').then(m => m.TutorPanelSubscribersModule)
            }
        ]
    },
    {
        path: '',
        component: WorkplaceComponent,
        canActivate: [PortalRouteService],
        children: [
            {
                path: 'portal',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./workplace/workplace-home/workplace-home.module').then(m => m.WorkplaceHomeModule)
            },
        ]
    },
    {
        path: '',
        component: ChannelsComponent,
        canActivate: [PortalRouteService],
        children: [
            {
                path: 'channels',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./channels/channels-home/channels-home.module').then(m => m.ChannelsHomeModule)
            },
            {
                path: 'channel',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./channels/channel/channel.module').then(m => m.ChannelModule)
            },
            {
                path: 'watch',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./channels/channels-watch/channels-watch.module').then(m => m.ChannelsWatchModule)
            }
        ]
    },
    {
        path: '',
        component: ExtensionComponent,
        children: [
            {
                path: 'extension',
                loadChildren: () => import('./extension/extension-home/extension-home.module').then(m => m.ExtensionHomeModule)
            },
            {
                path: 'extension/blox',
                loadChildren: () => import('./extension/extension-blox/extension-blox.module').then(m => m.ExtensionBloxModule)
            }
        ]
    },
    {
        path: '',
        component: AssessmentComponent,
        children: [
            {
                path: 'assessment',
                canLoad: [AuthGuardService],
                loadChildren: () => import('./assessment/assessment-home/assessment-home.module').then(m => m.AssessmentHomeModule)
            }
        ]
    }

];
