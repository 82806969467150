import { Injectable, OnInit } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, CanLoad, Route, UrlSegment } from '@angular/router';
import { AuthService } from './auth.service';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';
import { GlobalService } from './global.service';
import Swal from 'sweetalert2';

declare var swal: any;

@Injectable()
export class AuthGuardService implements CanLoad, CanActivate, OnInit {

    constructor(
        public global: GlobalService,
        public auth: AuthService, 
        public router: Router, 
        public route: ActivatedRoute
    ) {

    }

    ngOnInit(): void {
        console.log('iniciei');
    }

    canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
        console.log("CanLoad:",route.path);
        console.log("CanLoad QueryString:", this.global.params);
        //return true;
        if (this.global.loggedUser()) {
            if (['active-directory','login','recovery'].indexOf(route.path) >= 0) {
                this.router.navigate(['/portal']);
                return false;
            }
            return true;
        }
        else {
            if (this.global.params && this.global.params.token) {
                let brand = this.global.params.brand;
                if (this.global.params.token) {
                    return new Promise((resolve) => {
                        this.auth.tokenLogin(this.global.params.token, brand).then(
                            (response) => {
                                if (response) {
                                    if (['active-directory','login','recovery'].indexOf(route.path) >= 0) {

                                        let ref = localStorage.getItem('ref');
                                        
                                        if (ref) {
                                            localStorage.removeItem('ref');
                                            window.location.href = ref;
                                        }
                                        else {
                                            this.router.navigate(["/portal"]);
                                        }
                                    }
                                } 
                                resolve(true);
                            },
                            (error) => {
                                console.log("CanLoad",error,route.path);
                                this.global.params = null;
                                swal("Login via Token","Erro ao efetuar o Logon, por favor tente novamente!","error")
                                this.router.navigate(['/active-directory']);
                                resolve(false);
                            }
                        );
                    });
                }
            }
            else {
                if (['active-directory','login','recovery'].indexOf(route.path) >= 0) {
                    return true
                }
                this.router.navigate(['/active-directory']);
                return false;
            }
        }
    }

    canActivate(): Observable<boolean> | boolean {
        const clientId = sessionStorage.getItem('client');

        let verify = this.auth.verifyClient(+clientId).map(res => {
            console.log("AuthGuard.CanActivate: Verify ",res);
            if (res.success) {
                if ((this.router.url.indexOf('/active-directory') >= 0) || (this.router.url.indexOf('/login') >= 0) || (this.router.url.indexOf('/recovery') >= 0)) {
                    this.router.navigate(["/portal"]);
                }
                return true;
            } 
            else {
                this.router.navigate(['/erros/403']);
            }
        }).first(); 

        return verify;      
    }

    errorNotificate(msg: string): any {
        Swal.fire({
            title: 'Erro',
            text: msg,
            type: 'warning',
            confirmButtonClass: 'btn btn-success',
        });
    }
}
