import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { TrailService } from '../../@core/services/trail.service';
import { Trail } from '../../@core/models/trail';

@Component({
  selector: 'app-points-workplace',
  templateUrl: './points.component.html',
  providers: [ TrailService, Trail ]
})

export class PointsComponent implements OnInit, OnChanges {

  @Input() idTrail;
  @Input() points: number;
  @Input() textHelper: string;
  @Output() title: EventEmitter<any> = new EventEmitter
  public score: any;
  public progress: any;
  public loading = true;

  constructor(
    private _trailService: TrailService,
  ) { }

  ngOnInit() {
    this.score = this.points;
    if (this.idTrail) {
      this.findScore(this.idTrail);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.points) {
      this.score = changes.points.currentValue;
    }
    this.loading = false;
  }

  findScore(id): void {
    this._trailService.findTrainingScore(id)
    .subscribe((res) => {
        this.title.emit(res)
        this.score = res.data.pontos;
        this.progress = res.data.progresso;
        this.loading = false;
      },
      (error:any) => {
        console.log('Error findScore', error);
      })
  }

}
