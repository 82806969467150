import { Component, Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['../app.component.css']
})

@Injectable()
export class PublicComponent { 
  constructor(
    public notification: NotificationsService
  ) {

  }
}
