import { Component, ViewChild, OnInit } from '@angular/core';
import { ChannelService } from 'app/services/channel.service';
import { Global } from 'app/@core/lib/global';
import { AsapBaseCRUD } from 'app/@core/lib/asap-base-crud.component';
import { OwlCarousel } from 'ngx-owl-carousel';

@Component({
    selector: 'app-channel-library',
    templateUrl: './channel-library.component.html',
    providers: [ ChannelService, Global]
})

export class ChannelLibraryComponent implements OnInit {

    public library;
    public search = "";
    public page = null;
    public channelArray;
    public loading = true;


    @ViewChild('owlElement', {static:false}) owlElement: OwlCarousel

    options = {
      loop: true,
      center: false,
      margin: 0,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      URLhashListener: true,
      autoplayHoverPause: true,
      dots: false,
      responsive:{
          0:{
              items: 2.1,
              nav: false
          },
          1024:{
              items: 6.5,
              nav: false
          }
      }
    };

    constructor(
        public channelService: ChannelService,
        public global: Global,
    ) {
        this.doSearch("");
    }

    ngOnInit() {
        this.getChannels();
    }

    public doSearch(search,nextPage=false) {
        if (!nextPage) this.library = null;
        this.search = search;
        this.channelService.channeGrouplLibrary(this.search,this.page).subscribe((response:any) => {
            if (!nextPage) this.library = response.data;
            if (nextPage) this.library = this.library.concat(response.data);
            this.page = null;
            if (response.current_page != response.last_page) {
                this.page = response.current_page;
            }
        });
    }

    public moreVideos() {
        this.page++;
        this.doSearch(this.search,true);
    }

    public getChannels() {
        this.channelService.getResources({query: 'type=recommended'})
          .subscribe((res: any) => {
            console.log('getChannels', res.data);
            this.channelArray = res.data;
            this.loading = false;
            console.log('channelArray', this.channelArray);
          });
      }

}
