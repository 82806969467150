import { Component } from '@angular/core';

@Component({
  selector: 'app-workplace-spotlights',
  templateUrl: './workplace-spotlights.component.html',
  styleUrls: ['../../app.component.css', './workplace-spotlights.component.css']
})

export class WorkplaceSpotlightsComponent {

}
