import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { BaseService } from './loja/base.service';

@Injectable()
export class MenuService extends BaseService {

  constructor(protected http: Http) {
    super(http, 'api/v2/admin', 'menu/view');
  }

}
