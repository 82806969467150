import { Component } from '@angular/core';
import { TutorService } from 'app/services/tutor/tutor.service';

@Component({
    selector: 'app-tutor-panel',
    templateUrl: './tutor-panel.component.html',
    styleUrls: ['../app.component.css', './tutor-panel.component.css']
})
export class TutorPanelComponent {


}
