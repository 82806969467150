import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['../../app.component.css', './progress.component.css']
})

export class ProgressComponent implements OnChanges {

  loading = true;
  @Input() data;
  @Input() progress;
  @Input() progressoDados = 0;

  public doughnutChartLabels: string[] = ['Progresso'];
  public doughnutChartData: number[] = [350];
  public doughnutChartType: string = 'doughnut';
  public doughnutColors: Array<any> = [
    { backgroundColor: ['#FFAC20', '#CCC'] }
  ];
  public doughnutsOptions: any = {
    responsive: true,
    cutoutPercentage: 80,
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    }
  }



  public barChartLabels: string[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartType: string = 'bar';
  public barChartLegend: boolean = true;
  public barChartColors: Array<any> = [
    { backgroundColor: '#1C96C9' }
  ];
  public barChartData: any[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Pontos na semana' }
  ];

  public barChartOptions: any = {
    global: {
      maintainAspectRatio: true
    },
    scales: {
      yAxes: [{
        gridLines: {
          display: false,
          drawBorder: false
        },
        ticks: {
          display: false
        }
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
        },
        gridLines: {
          display: false
        },
        ticks: {
          display: true,
          fontSize: 14,
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0
        }
      }]
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    }
  }


  constructor(
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data || changes.progress) {
      this.loadData();
    }
  }

  loadData() {
    this.getData(this.data);
    this.getProgress(this.progress);
    console.log('DATA', this.data);
  }

  getData(result = null) {
    if (result) {
      this.barChartLabels = [];
      this.barChartData = [];
      let data = [];
      let date = []
      for (let item of result) {
        let separated = item.date.split('-')
        data.push(item.pontos)
        date.push(separated[2] + '/' + separated[1])
      }
      this.barChartData.push(
        { data: data, label: 'Pontos na semana' }
      )
      this.barChartLabels = data;
      this.loading = false;
      this.cdRef.detectChanges();
    }
  }

  getProgress(result) {
    this.doughnutChartData = []
    this.doughnutChartData.push(result);
    this.doughnutChartData.push(100 - result);
    this.cdRef.detectChanges();

    this.loading = false;
  }

}
