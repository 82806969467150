import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-workplace-search',
  templateUrl: './workplace-search.component.html',
  styleUrls: ['../../app.component.css', './workplace-search.component.css'],
})

export class WorkplaceSearchComponent {

    @Output() onSearch = new EventEmitter<any>();
    @Input() placeholder = 'O que você procura?';

    public doSearch(input) {
        if (this.onSearch) {
          this.onSearch.emit(input.value);
        }
    }

}
